<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button
              v-if="false"
              class="reEmailBt"
              type="primary"
            >重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li><span>代付订单号</span><i class="hidden1">{{detail.mchPayNo?detail.mchPayNo:''}}</i></li>
          <li><span>代付流水号</span><i class="hidden1">{{detail.plfPayNo?detail.plfPayNo:''}}</i></li>
          <li><span>三方代付订单号</span><i class="hidden1">{{detail.thdPayNo?detail.thdPayNo:''}}</i></li>
          <li style="height: 19px;"></li>
          <li><span>商户名称</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <b>{{detail.mchName}}</b>
              </el-tooltip>
            </i></li>
          <li><span>商户编号</span><i class="hidden1">{{detail.mchId?detail.mchId:''}}</i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName?detail.orgName:''}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo?detail.channelMchNo:''}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime?detail.createTime:''}}</i></li>
          <li><span>交易时间</span><i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i></li>
          <li><span>产品类型</span><i class="hidden1">{{payType[detail.payType]}}</i></li>
          <li><span>交易状态</span><i class="hidden1">{{payStatus[detail.payStatus]}}</i></li>
          <li><span>账户卡号</span><i class="hidden1">{{detail.accountNo?detail.accountNo:''}}</i></li>
          <li><span>账户名称</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.accountName"
                placement="top"
              >
                <b>{{detail.accountName}}</b>
              </el-tooltip>
            </i></li>
          <li><span>交易金额</span><i class="hidden1">{{detail.payAmount/100 | formatMoney}}</i></li>
          <li><span>手续费</span><i class="hidden1">{{detail.fee/100 | formatMoney}}</i></li>
          <li><span>总金额</span><i class="hidden1">{{detail.totalAmount/100 | formatMoney}}</i></li>
          <li><span>代付用途</span><i class="hidden1">{{paidUse[detail.paidUse]}}</i></li>
          <li><span>说明</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.body"
                placement="top"
              >
                <b>{{detail.body}}</b>
              </el-tooltip>
            </i></li>
          <li><span>通知地址</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.notifyUrl"
                placement="top"
              >
                <b>{{detail.notifyUrl}}</b>
              </el-tooltip>
            </i></li>
          <li><span>代付应答码</span><i class="hidden1">{{detail.resultCode?detail.resultCode:''}}</i></li>
          <li><span>应答码描述</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.resultMessage"
                placement="top"
              >
                <b>{{detail.resultMessage}}</b>
              </el-tooltip>
            </i></li>
          <li><span>异常错误码</span><i class="hidden1">{{detail.errorCode?detail.errorCode:''}}</i></li>
          <li><span>错误描述</span><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.errorMessage"
                placement="top"
              >
                <b>{{detail.errorMessage}}</b>
              </el-tooltip>
            </i></li>
          <!-- <li><span>代付明细状态码</span><i>2013423343245</i></li> -->
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">代付通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr
            v-for="(v,i) in notices"
            :key="i"
          >
            <td>
              <p>{{v.notifyTime}}</p>
            </td>
            <td>
              <p>{{notifyType[v.notifyType]}}</p>
            </td>
            <td>
              <p>{{v.reachStatus?'已到达':'未响应'}}</p>
            </td>
            <td>
              <p>{{notifyStatus[v.notifyStatus]}}</p>
            </td>
            <td>
              <p>{{v.resultCode}}</p>
            </td>
            <td>
              <p>{{v.resultMessage}}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPaysDetail
} from '@/api/trading/payment.js'
export default {
  data() {
    return {
      crumbs: "交易详情",
      detail: '',
      notices: '',
      payType: {
        BANK_PAY_DAILY_ORDER: '朝夕付',
        BANK_PAY_MAT_ENDOWMENT_ORDER: '任意付'
      },
      payStatus: {
        PAYING: '交易处理中',
        FAIL: '交易失败',
        REACHED: '交易成功'
      },
      paidUse: {
        201: '工资资金',
        202: '活动经费',
        203: '养老金',
        204: '货款',
        205: '劳务费',
        206: '保险理财',
        207: '资金下发',
        208: '营业款',
        210: '退回款项',
        211: '消费款项',
        209: '其他'
      },
      notifyType: {
        PLATFORM: '平台',
        THIRD: '第三方'
      },
      notifyStatus: {
        SUCCESS: '成功',
        FAIL: '失败'
      }
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail() {
      getPaysDetail(this.$route.query.id).then(res => {
        if (res) {
          this.detail = res.resultData.detail
          this.notices = res.resultData.notices
        }
      })
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data
    },
  }
}
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
