import axios from '@/utils/request'

// 代付查询-代付列表
export function getPays(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/pay/pays`,
    method: 'get',
    params
  })
}
// 代付查询-代付搜索
export function getPaysSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/pay/pays/search`,
    method: 'get',
    params
  })
}
// 代付查询-代付详情
export function getPaysDetail(payId) {
  return axios({
    url: `/manager/trade/pay/pays/${payId}`,
    method: 'get'
  })
}
// 代付管理-账户列表
export function getAccounts(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/pay/accounts`,
    method: 'get',
    params
  })
}
// 代付管理-账户详情
export function getAccountsDetail(mchId) {
  return axios({
    url: `/manager/trade/pay/accounts/${mchId}`,
    method: 'get'
  })
}
// 代付管理-开启/关闭账户
export function setAccountsStatus(data) {
  return axios({
    url: `/manager/trade/pay/accounts/${data.mchId}/status`,
    method: 'put',
    data
  })
}
// 代付查询-订单同步
export function getAccountsSync(payId,type) {
  return axios({
    url: `/manager/trade/pay/pays/${payId}/sync`,
    method: 'get',
     Headers: {
      'type': type
    }
  })
}
//代付查询-代付通知
export function getAccountsNotify(payId,type) {
  return axios({
    url: `/manager/trade/pay/pays/${payId}/notify`,
    method: 'post',
    Headers: {
      'type': type
    }
  })
}
//代付查询-订单导出
export function exportPays(params) {
  return axios({
    responseType: 'blob',
    url: `/manager/trade/pay/pays/export`,
    method: 'post',
    params
  })
}
